/* eslint-disable */
// https://rsuitejs.com/components/carousel/
import cn from 'classnames';
import type { FormEvent } from 'react';
import React, { useState } from 'react';
import type { CarouselProps } from 'rsuite';
import { Carousel as CarouselRrsuite } from 'rsuite';

// import './_variables.css';
import css from './carousel.module.scss';

interface IProps extends CarouselProps {
  className?: string;
  urls: string[];
}

const DEFAULT_ACTIVE_INDEX = 0;

export function Carousel(props: IProps) {
  const { className, urls } = props;
  const [activeIndex, setActiveIndex] = useState(DEFAULT_ACTIVE_INDEX);

  return (
    <div className={cn(css.wrapper, className)}>
      <div
        className={css.arrowLeft}
        onClick={() => {
          const value = activeIndex === 0 ? urls.length - 1 : activeIndex - 1;

          setActiveIndex(value);
        }}
      >
        {'<'}
      </div>

      <CarouselRrsuite
        // name="mainCarousel"
        placement="bottom"
        shape="bar"
        className={css.carousel}
        onChange={(event: FormEvent) => {
          const target = event.target as HTMLInputElement;

          setActiveIndex(+target.value);
        }}
        autoplay
        activeIndex={activeIndex}
        defaultActiveIndex={DEFAULT_ACTIVE_INDEX}
      >
        {urls.map((url, i) => (
          <img className={css.img} key={url} alt={`slide-${i}`} src={url} />
        ))}
      </CarouselRrsuite>

      <div
        className={css.arrowRight}
        onClick={() => {
          const value = activeIndex === urls.length - 1 ? 0 : activeIndex + 1;

          setActiveIndex(value);
        }}
      >
        {'>'}
      </div>
    </div>
  );
}
