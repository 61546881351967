import { useQuery } from '@tanstack/react-query';
import type { TExchangeRate, TProductListItem } from 'api';
import { getProductList } from 'api';
import React, { useMemo } from 'react';

import type { TStoreFilter } from 'constants/store';
import { STORE } from 'constants/store';

import Card from 'components/Card';
import Carousel from 'components/Carousel';
import Loader from 'components/Loader';

import { shuffle } from 'utils/shuffle';

import { useLocale } from '../../../hooks/useLocale';

import css from './mainPage.module.scss';

export const MainPage = (): JSX.Element => {
  const [locale] = useLocale();
  const MOCK_CAROUSEL_URLS = [`/assets/${locale}-main-page-picture-1.png`];
  const { data: exchangeRate } = useQuery<TExchangeRate>([STORE.EXCHANGE_RATE]);
  const {
    data: productList,
    isLoading: productListLoading,
    // error: productListError,
  } = useQuery({
    queryKey: [STORE.PRODUCT_LIST, exchangeRate.conversionRate, locale],
    queryFn: getProductList,
  });
  const { data: filter } = useQuery<TStoreFilter>([STORE.FILTER]);
  const filteredCards: TProductListItem[] = useMemo(() => {
    if (productList) {
      const searchValue = filter ? filter.searchValue.toLowerCase() : '';
      const filteredList = productList.filter(item =>
        item.productName.toLowerCase().includes(searchValue),
      );

      return shuffle(filteredList);
    } else {
      return [];
    }
  }, [productList, filter]);

  return (
    <div className={css.wrapper}>
      {productListLoading && (
        <div className={css.loaderWrapper}>
          <Loader className={css.loader} />
        </div>
      )}
      <Carousel className={css.carouselWrapper} urls={MOCK_CAROUSEL_URLS} />
      <div className={css.cards}>
        {filteredCards.map((item: TProductListItem) => (
          <React.Fragment key={item.id}>
            <Card data={item} />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};
