/* eslint-disable */
import cn from 'classnames';
import React from 'react';

import css from './img.module.scss';

interface TProps {
  url: string;
  loaderUrl: string;
  className?: string;
}

export function Img(props: TProps) {
  const { className, loaderUrl, url, ...otherProps } = props;

  return (
    <div className={cn(className, css.wrapper)}>
      <img
        className={css.loadingIng}
        alt="loading"
        src={loaderUrl}
        {...otherProps}
      />
      <picture>
        <img
          className={css.mainIng}
          alt="img"
          src={url}
          loading="lazy"
          decoding="async"
          {...otherProps}
        />
      </picture>
    </div>
  );
}
